import Script from "next/script";
import { FC, useCallback, useEffect, useMemo } from "react";
import { SkinVersions } from "@finbackoffice/site-server-core";
import { useRuntimeConfig } from "@finbackoffice/site-core";
import Link from "next/link";
import Img from "components/base/img/Img";
import styles from "./license.module.sass";

type License = {
    anj_id?: string;
    apg_id?: string;
    gcb_id?: string;
    imgSize: string;
    imgType: "basic-small" | "basic-light-large" | "";
};

const LICENSE: Record<string, License> = {
    [SkinVersions.Betmidas]: {
        gcb_id: "ZXlKcGRpSTZJamxxY0hsWUwyZElXbWd4WWtaSkx6SkViRzV1WVZFOVBTSXNJblpoYkhWbElqb2lVVlo2VUVadU1pOVFlbmhWU21GVFZWbFVRV2t2VVQwOUlpd2liV0ZqSWpvaU1HWTRZelZrWkRCaFlXVm1ZbVF4Tm1Zd016Tm1ZMlExWVRFNE1HRTJNalF6TUdZMVpqa3lNak15T0dKaE4yUmhORFZqWm1GaFlUWTJZVFUwTUdJek15SXNJblJoWnlJNklpSjk=",
        imgSize: "90",
        imgType: "basic-light-large",
    },
    [SkinVersions.Xrpbet]: {
        anj_id: "96cf5f60-ea14-4901-88d4-b4f68d3c6203",
        imgSize: "128",
        imgType: "basic-small",
    },
    [SkinVersions.Betvivo]: {
        gcb_id: "ZXlKcGRpSTZJbGhZVDBGVU0yVjBOVWRJUjBjd04zaHdhWFZzWWxFOVBTSXNJblpoYkhWbElqb2liV29yY0VKWV",
        imgSize: "80",
        imgType: "basic-light-large",
    },
    [SkinVersions.Betvivocomz]: {
        anj_id: "5dbf5b48-719c-47e2-86fe-04228ae04ef7",
        imgSize: "128",
        imgType: "basic-small",
    },
    [SkinVersions["10xbet"]]: {
        gcb_id: "ZXlKcGRpSTZJbHA1ZDFaaVJTdHdUVzFvS3poQlNXeDVVVTVzY1djOVBTSXNJblpoYkhWbElqb2lWblJZYmxO",
        imgSize: "",
        imgType: "",
    },
};

const LicenseLogo: FC = () => {
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const license = LICENSE.hasOwnProperty(COMMON_SITE_CONFIGS.skinVersion)
        ? LICENSE[COMMON_SITE_CONFIGS.skinVersion]
        : null;
    const windowScriptApg = `apg_${license?.apg_id?.replaceAll("-", "_")}`;
    const windowScriptAnjour = `anj_${license?.anj_id?.replaceAll("-", "_")}`;

    useEffect(() => {
        if (license?.apg_id && typeof window[windowScriptApg as any] !== "undefined") {
            (window[windowScriptApg as any] as any)?.init();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (license?.anj_id && typeof window[windowScriptAnjour as any] !== "undefined") {
            (window[windowScriptAnjour as any] as any)?.init();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAntillephoneScriptLoad = useCallback(() => {
        (window[windowScriptApg as any] as any)?.init();
    }, [windowScriptApg]);

    const onAnjouanGamingScriptLoad = useCallback(() => {
        (window[windowScriptAnjour as any] as any)?.init();
    }, [windowScriptAnjour]);

    const renderContent = useMemo(
        () =>
            license && (
                <>
                    {license.apg_id && (
                        <>
                            <div
                                id={`apg-${license.apg_id}`}
                                data-apg-seal-id={license.apg_id}
                                data-apg-image-size={license.imgSize}
                                data-apg-image-type={license.imgType}
                            />
                            <Script
                                src={`https://${license.apg_id}.snippet.antillephone.com/apg-seal.js`}
                                onLoad={onAntillephoneScriptLoad}
                            />
                        </>
                    )}
                    {license.gcb_id && (
                        <Link
                            target="_blank"
                            href={`https://cert.gcb.cw/certificate?id=${license.gcb_id}`}
                            className={styles.betmidasLink}>
                            <Img
                                source={`${ASSETS_URL}/common/footer/gcb_license_logo.png`}
                                alt="gcb_license"
                                width={150}
                                height={85}
                            />
                        </Link>
                    )}
                    {license.anj_id && (
                        <>
                            <div
                                id={`anj-${license.anj_id}`}
                                data-anj-seal-id={license.anj_id}
                                data-anj-image-size={license.imgSize}
                                data-anj-image-type={license.imgType}
                            />
                            <Script
                                src={`https://${license.anj_id}.snippet.anjouangaming.org/anj-seal.js`}
                                onLoad={onAnjouanGamingScriptLoad}
                            />
                        </>
                    )}
                </>
            ),
        [license, onAntillephoneScriptLoad, ASSETS_URL, onAnjouanGamingScriptLoad],
    );

    return renderContent;
};

export default LicenseLogo;
